import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.jpg';
import contactButtonImageSectionImage from '@commons/contact-button-image-section/images/image2.svg';
import Nutanix from '@images/logos-partners/Nutanix-logo.svg';

import {
  playwright,
  cypress,
  selenium,
  pytest,
  robotFramework,
  pyATS,
  p4,
  jest,
  python,
  java,
  typeScript,
  jenkins,
  gitLab,
  gitHubActions,
  circleCI,
  helm,
  ansible,
  maas,
  nornir,
  napalm,
  netmiko,
  nita,
  docker,
  tRex,
  iperf,
  dpdk,
  apacheJMeter,
  postman,
  soapUI,
  graphQL,
  pact,
  allureReport,
  paramiko,
  scapy,
  pandas,
  jinja,
} from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Test Automation Services Company -  CodiLime | Strategic Partner',
  description:
    'Test automation is a way to accelerate your time to market and minimize human mistakes. Find out how we help our clients with test automation services.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Test <br />
      automation <br />
      services
    </>
  ),
  isBorder: false,
};

export const sectionUnderHeaderProps = {
  contactButtonLabel: 'Automate with us',
  textPartOne: (
    <>
      Limited resources and time-to-market requirements are the challenges that every technology company faces. The
      answer to saving time while maintaining the quality of software products lies in test automation.
    </>
  ),
  textPartTwo: (
    <>
      See how we use our expertise to provide highly functional, scalable test automation suited to your business needs.
    </>
  ),
};

export const blocksInRowsWithOneActiveProps = {
  title: 'Software test automation services',
  subtitle: (
    <>
      When we work with clients, we take on full responsibility for your product&apos;s reliability, starting from
      Quality Assurance at the development stage up to end-user tests, including UX. In our test automation solutions,
      we strive to use all the benefits of automation. Whether you&apos;re a startup looking for UI tests or an
      enterprise in need of custom test automation frameworks, we&apos;ve got you covered.
    </>
  ),
  blocks: [
    {
      title: 'Automated functional and non-functional testing',
      description: (
        <>
          We deliver <b>automatic testing</b> for your product based on gathered and arranged requirements. We identify
          what to test and what not to and how to choose a sufficient level of testing. The automated tests we write
          help you to <b>keep regression in check</b> and give you and your team an overall tool to assess the quality
          of the product.
        </>
      ),
    },
    {
      title: 'Automated performance testing for network and cloud products',
      description: (
        <>
          We provide reliable, flexible, and scalable software solutions based on well-fixed standards like RFC2544 for
          performance testing your network and cloud products. The solutions are tailored to your needs and enhanced
          with <b>automatic setup and CI/CD integration</b>, so you are able to continuously monitor your product
          performance and quickly find out about any regression.
        </>
      ),
    },
    {
      title: 'CI/CD implementation',
      description: (
        <>
          Automatic tests bring the best results if they&apos;re run as often as possible. To help you with that, we
          design and implement <b>CI/CD pipelines</b> that will take your software development cycle to a whole new
          level. We know how important it is that all pieces (static analysis, code review, functional tests,
          non-functional tests, etc.) fall into a puzzle to <b>optimize your development speed</b>.
        </>
      ),
    },
    {
      title: 'Test automation framework and testing tools development',
      description: (
        <>
          We have experience in building <b>large testing frameworks</b> and testing tools that support different types
          of tests and integrate well with your product&apos;s technologies. We focus on best practices for SW
          development to provide you with easy-to-maintain, scalable, readable, reusable, and robust software.
        </>
      ),
    },
    {
      title: 'Test lab automation',
      description: (
        <>
          Test lab automatization can be a challenge, especially when you have your own proprietary hardware. It is
          essential for software testing to be fast and reliable. We help in <b>organizing the lab</b>, configuring it,
          choosing the tools for automatization, and integrating it with test suites. We also have experience in{' '}
          <b>running our own lab</b> for different software development projects.
        </>
      ),
    },
    {
      title: 'Automatic test reporting and monitoring',
      description: (
        <>
          Tests should give you proper information about your project. We deliver <b>automated test results reports</b>,
          coverage reports, performance reports, and many more to provide you with better project quality visibility and
          reinforce your decision-making. We also integrate the reporting solutions with CI/CD and other automatization
          solutions that you are using.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveSection,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    subtitleStyles: styles.blocksInRowsWithOneActiveSubtitle,
    singleBlockContainer: styles.blocksInRowsWithOneActiveSingleBlockContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageSection,
    },
  },
  title: <>Ensure your product quality with automated testing</>,
  image: contactButtonImageSectionImage,
  imageAlt: 'Use automated testing to ensure the quality of your products',
  isH2: true,
  classNames: {
    customContent: styles.contactButtonImageSectionContent,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionCustomContainer,
  },
};

export const techStackWithCategoriesProps = {
  sectionProps: {
    title: 'Technology stack',
    subtitle: (
      <>
        Our test automation team is proficient in various programming languages and familiar with commonly used as well
        as emerging technologies. We offer a wide range of skills in different technologies to fit into our
        clients&apos; existing infrastructure and fulfill their requirements.
      </>
    ),
    classNames: {
      titlesContainer: styles.techStackTitlesContainer,
    },
  },
  classNames: {
    innerWrapper: styles.techStackInnerWrapper,
    singleColumn: styles.techStackSingleColumn,
    singleCategory: styles.techStackSingleCategory,
    categoryTitle: styles.techStackCategoryTitle,
    iconsForCategory: styles.techStackIconsForCategory,
    containerWithTwoColumns: styles.techStackTwoColumns,
  },
  blocksForFirstColumn: [
    {
      categoryTitle: 'Test frameworks',
      technologiesData: [playwright, cypress, selenium, pytest, robotFramework, pyATS, p4, jest],
    },
    {
      categoryTitle: 'Languages',
      technologiesData: [python, java, typeScript],
    },
    {
      categoryTitle: 'CI/CD',
      technologiesData: [jenkins, gitLab, gitHubActions, circleCI],
    },
    {
      categoryTitle: 'Infrastructure automation',
      technologiesData: [helm, ansible, maas, nornir, napalm, pyATS, netmiko, nita, docker],
    },
    {
      categoryTitle: 'Test and other tools',
      technologiesData: [
        tRex,
        iperf,
        dpdk,
        apacheJMeter,
        postman,
        soapUI,
        graphQL,
        pact,
        allureReport,
        paramiko,
        scapy,
        pandas,
        jinja,
      ],
    },
  ],
};

export const testimonialProps = {
  id: 'testimonial',
  testimonial: {
    quote:
      'CodiLime’s DevOps teams helped us reduce our development cycle time by 50%, and its software engineering ' +
      'helped us improve the quality of our product by increasing our test coverage by more than 80% in crucial parts ' +
      'of our system. This allows us to deliver new features and capabilities seamlessly without compromising on ' +
      'simplicity to our global customer base. We’re looking forward to further collaboration with CodiLime as the ' +
      'Epoch product evolves with time.',
    author: 'Harjot Gill - General Manager Nutanix Xi Epoch',
    logo: Nutanix,
    logoAlt: 'Nutanix',
  },
  leftTitle: 'What our clients say about us',
  classNames: {
    innerWrapper: styles.testimonialInnerWrapper,
  },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'See our publications about test automation',
    subtitle: (
      <>
        Our engineers and test automation experts are eager to share their experiences on our blog. See our articles to
        learn more about the benefits of automated testing and the technologies we use.
      </>
    ),
    classNames: {
      innerWrapper: styles.blocksRedirectingToResourcesInnerWrapper,
      titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer,
    },
  },
  blocks: [
    {
      text: 'QA automation - what is it and why you need it?',
      link: '/blog/qa-automation-what-is-it-and-why-do-you-need-it/',
    },
    {
      text: 'How to build a test automation framework in the cloud?',
      link: '/blog/how-to-build-a-test-automation-framework-in-the-cloud/',
    },
    {
      text: 'Best tools for functional testing in networks',
      link: '/blog/tools-functional-testing-networks/',
    },
    {
      text: 'The best QA automation tools that you should know',
      link: '/blog/qa-automation-tools/',
    },
    {
      text: 'CI/CD pipeline: why it makes sense for your startup',
      link: '/blog/ci-cd-pipeline-for-your-startup/',
    },
    {
      text: 'Traffic generator for measuring network: TRex customized',
      link: '/blog/a-traffic-generator-for-measuring-network-performance/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Our other services',
  subtitle: `Our expertise exceeds far beyond testing services. CodiLime is primarily a network-focused company, but we don't lack software engineering talents, data specialists, and UX/UI designers. See what else we can do for you.`,
  blocks: [
    {
      text: (
        <>
          <span>R&D services</span>
        </>
      ),
      link: paths.SERVICES.R_D,
    },
    {
      text: (
        <>
          <span>Network Professional</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
    {
      text: (
        <>
          <span>Custom software </span>
          <span>development services</span>
        </>
      ),
      link: paths.SERVICES.CUSTOM_DEVELOPMENT,
    },
    {
      text: (
        <>
          <span>MVP development</span>
        </>
      ),
      link: paths.SERVICES.MVP,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksWithRedirectsInnerWrapper,
  },
};

export const opacityScrolledSliderProps = {
  title: 'The test automation benefits for your business',
  id: 'benefits',
  textBlocks: [
    <>
      <span>Saved money and time</span> thanks to limited human effort
    </>,
    <>
      Improved accuracy by <span>eliminating manual errors</span> during testing
    </>,
    <>
      Ensured <span>continuous testing</span> thanks to automation
    </>,
    <>
      Increased <span>scalability</span> of testing
    </>,
    <>
      Improved <span>test coverage</span>
    </>,
  ],
};

export const blocksInRowsWithOneWiderProps = {
  title: 'Our expertise in automation testing',
  subtitle: (
    <>
      At CodiLime, we have over 12 years of experience working with industry leaders and successful startups. We have
      worked on various projects, including test automation in the cloud, network, software development, data
      engineering, and other fields. Our test automation services helped them ensure product quality and significantly
      cut down the release cycle time.
    </>
  ),
  id: 'use-cases',
  blocks: [
    {
      title: 'RFC2544 performance validation',
      description: (
        <>
          We provided the solutions for automating the <b>RFC2544 performance validation</b> for different network
          devices and services, including virtual ones. We used an SW traffic generator TRex from Cisco. We also
          incorporated the solution into the CI/CD process.
        </>
      ),
      background: '#062338',
    },
    {
      title: 'Benchmarking framework for SmartNICs',
      description: (
        <>
          We helped the client to stay up to date with the industry&apos;s best practices by providing a{' '}
          <b>benchmarking framework for Smart Network Interface Cards</b>. We built the test automation framework using
          Python, DPDK and SW traffic generator TRex from Cisco.
        </>
      ),
      background: '#041C2E',
    },
    {
      title: 'UI testing for network application',
      description: (
        <>
          We worked with a client on a <b>network alert management application</b> with microservice architecture. Our
          task was to prepare a user interface testing solution. We delivered an automated UI test that focused on
          integration, API contracts, and CI/CD.
        </>
      ),
      background: '#122B3F',
    },
    {
      title: 'Test framework for SDN solution',
      description: (
        <>
          We built an extensive <b>test framework for the whole SDN solution</b>. We used Python to develop the
          framework. The proposed solution included test lab automation, testbed resources management, and CI/CD.
        </>
      ),
      background: '#053356',
    },
    {
      title: 'Test framework for SmartNIC offloading',
      description: (
        <>
          We created a <b>test framework for Smart Network Interface Cards offloading solution</b> for SDN 3rd party
          product supporting development team. We used Python to build it. The proposed framework performed hardware
          integration testing and acceptance tests for vendor verification.
        </>
      ),
      background: '#122B3F',
    },
    {
      title: 'End-to-end UI and API tests',
      description: (
        <>
          We used an extensive tech stack, including Selenium, Cypress, and Playwright, to create an{' '}
          <b>E2E user interface and API tests</b>. We worked on such solutions for web applications in projects for
          various industries, including networks, big data, and monitoring.
        </>
      ),
      background: '#041C2E',
    },
  ],
  classNames: {
    innerWrapper: styles.blocksInRowsInnerWrapper,
    blockContainerActive: styles.blocksInRowsBlockContainerActive,
  },
};
