// extracted by mini-css-extract-plugin
export var blocksInRowsBlockContainerActive = "payload-module--blocks-in-rows-block-container-active--c14fd";
export var blocksInRowsInnerWrapper = "payload-module--blocks-in-rows-inner-wrapper--86e58";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--c746e";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--c6ff8";
export var blocksInRowsWithOneActiveSingleBlockContainer = "payload-module--blocks-in-rows-with-one-active-single-block-container--34ad5";
export var blocksInRowsWithOneActiveSubtitle = "payload-module--blocks-in-rows-with-one-active-subtitle--b850b";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--c4f67";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--089b7";
export var coloredBlocksWithRedirectsInnerWrapper = "payload-module--colored-blocks-with-redirects-inner-wrapper--04d42";
export var contactButtonImageSection = "payload-module--contact-button-image-section--91346";
export var contactButtonImageSectionContent = "payload-module--contact-button-image-section-content--699a0";
export var contactButtonImageSectionCustomContainer = "payload-module--contact-button-image-section-custom-container--20060";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--088be";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--86385";
export var techStackCategoryTitle = "payload-module--tech-stack-category-title--87d15";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--33b5a";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--8ed02";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--33973";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--2afbb";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--2eb79";
export var techStackTwoColumns = "payload-module--tech-stack-two-columns--9486c";
export var testimonialInnerWrapper = "payload-module--testimonial-inner-wrapper--88fe4";
export var titleBorderWidth = "payload-module--title-border-width--9573f";
export var underHeaderImageContainer = "payload-module--under-header-image-container--35a07";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--4f18e";